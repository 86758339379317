var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"main"}},[_c('TheMenu'),_c('div',{attrs:{"id":"content"}},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v("Status")]),_vm._m(0),_c('div',{staticClass:"itens-accordion"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"panel-title",on:{"click":function($event){return _vm.handleAccordion()}}},[_vm._v("Tela 1")]),_vm._m(1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"panel-title active",on:{"click":function($event){return _vm.handleAccordion()}}},[_vm._v("Tela 2")]),_vm._m(2)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"panel-title",on:{"click":function($event){return _vm.handleAccordion()}}},[_vm._v("Tela 3")]),_vm._m(3)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"panel-title",on:{"click":function($event){return _vm.handleAccordion()}}},[_vm._v("Tela 4")]),_vm._m(4)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"panel-title",on:{"click":function($event){return _vm.handleAccordion()}}},[_vm._v("Tela 5")]),_vm._m(5)])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lista-telas"},[_c('div',{staticClass:"item"},[_c('h3',[_vm._v("Tela 1")]),_c('span',{staticClass:"status online"},[_vm._v("Online")])]),_c('div',{staticClass:"item"},[_c('h3',[_vm._v("Tela 2")]),_c('span',{staticClass:"status offline"},[_vm._v("Offline")])]),_c('div',{staticClass:"item"},[_c('h3',[_vm._v("Tela 3")]),_c('span',{staticClass:"status online"},[_vm._v("Online")])]),_c('div',{staticClass:"item"},[_c('h3',[_vm._v("Tela 4")]),_c('span',{staticClass:"status online"},[_vm._v("Online")])]),_c('div',{staticClass:"item"},[_c('h3',[_vm._v("Tela 5")]),_c('span',{staticClass:"status online"},[_vm._v("Online")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cont"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/foto-promo-1.jpg"),"alt":""}})]),_c('form',{attrs:{"action":""}},[_c('div',{staticClass:"form-item"},[_c('p',[_c('strong',[_vm._v("Status:")]),_vm._v(" "),_c('span',{staticClass:"status online"},[_vm._v("Online")])])]),_c('div',{staticClass:"form-item"},[_c('button',{staticClass:"btn ligar"},[_vm._v("Ligar")])]),_c('div',{staticClass:"form-item"},[_c('button',{staticClass:"btn reiniciar"},[_vm._v("Reiniciar")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cont active"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/foto-promo-1.jpg"),"alt":""}})]),_c('form',{attrs:{"action":""}},[_c('div',{staticClass:"form-item"},[_c('p',[_c('strong',[_vm._v("Status:")]),_vm._v(" "),_c('span',{staticClass:"status offline"},[_vm._v("Offline")])])]),_c('div',{staticClass:"form-item"},[_c('button',{staticClass:"btn ligar"},[_vm._v("Ligar")])]),_c('div',{staticClass:"form-item"},[_c('button',{staticClass:"btn reiniciar"},[_vm._v("Reiniciar")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cont"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/foto-promo-1.jpg"),"alt":""}})]),_c('form',{attrs:{"action":""}},[_c('div',{staticClass:"form-item"},[_c('p',[_c('strong',[_vm._v("Status:")]),_vm._v(" "),_c('span',{staticClass:"status online"},[_vm._v("Online")])])]),_c('div',{staticClass:"form-item"},[_c('button',{staticClass:"btn ligar"},[_vm._v("Ligar")])]),_c('div',{staticClass:"form-item"},[_c('button',{staticClass:"btn reiniciar"},[_vm._v("Reiniciar")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cont"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/foto-promo-1.jpg"),"alt":""}})]),_c('form',{attrs:{"action":""}},[_c('div',{staticClass:"form-item"},[_c('p',[_c('strong',[_vm._v("Status:")]),_vm._v(" "),_c('span',{staticClass:"status online"},[_vm._v("Online")])])]),_c('div',{staticClass:"form-item"},[_c('button',{staticClass:"btn ligar"},[_vm._v("Ligar")])]),_c('div',{staticClass:"form-item"},[_c('button',{staticClass:"btn reiniciar"},[_vm._v("Reiniciar")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cont"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/foto-promo-1.jpg"),"alt":""}})]),_c('form',{attrs:{"action":""}},[_c('div',{staticClass:"form-item"},[_c('p',[_c('strong',[_vm._v("Status:")]),_vm._v(" "),_c('span',{staticClass:"status online"},[_vm._v("Online")])])]),_c('div',{staticClass:"form-item"},[_c('button',{staticClass:"btn ligar"},[_vm._v("Ligar")])]),_c('div',{staticClass:"form-item"},[_c('button',{staticClass:"btn reiniciar"},[_vm._v("Reiniciar")])])])])
}]

export { render, staticRenderFns }