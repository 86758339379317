import { render, staticRenderFns } from "./GerenciadorView.vue?vue&type=template&id=1e2ec340&scoped=true&"
import script from "./GerenciadorView.vue?vue&type=script&lang=js&"
export * from "./GerenciadorView.vue?vue&type=script&lang=js&"
import style0 from "./GerenciadorView.vue?vue&type=style&index=0&id=1e2ec340&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e2ec340",
  null
  
)

export default component.exports